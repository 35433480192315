import { createSlice } from "@reduxjs/toolkit";
import { userComponent } from "../../interfaces";

interface setUsercomponentAction {
    payload: userComponent
}

export const counterSlice = createSlice({
    name: 'viewControl',
    initialState: {
        userComponent: userComponent.Login
    },
    reducers: {
        setUserComponent: (state, action: setUsercomponentAction)=>{
            state.userComponent = action.payload
        }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setUserComponent } = counterSlice.actions
  
  export default counterSlice.reducer