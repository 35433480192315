enum userComponent{
    "Login",
    "Signup",
    "ForgotPassword"
}
enum AnswerType{
    "SingleNumber",
    "SingleText",
    "Multi",
    "Sort",
    "Range",
    "FreeNumber",
    "FreeText"
}
interface Answer {
    _from?: string,
    _to: string,
    active?: boolean,
    Strength?: number,
    Range?: [number, number],
    Text?: String,
    Anchor?: number
}

interface FollowupQuestion {
    _id?: string,
    _to: string,
    Strength: number|[number, number],
    _from?: string,
    Offset?:number|[number, number]
}
interface ValidAnswer {
    _id?: string,
    Answer: string,
    asset?: string,
    FollowupQuestions?: Array<FollowupQuestion>,
    Anchor?: number
}
interface Question {
    _id: string,
    Question: string,
    QuestionHint?: string,
    QuestionGroups?: Array<string>,
    AnswerType: string,
    ValidAnswers: Array<ValidAnswer>
}
interface SingleNumberRange extends Question {
    min: number,
    max: number,
    step: number,
    singleAsset?: string,
    FollowupQuestions?: Array<FollowupQuestion>
}
export type {Question, ValidAnswer, FollowupQuestion, Answer, SingleNumberRange }
export {userComponent, AnswerType}