import { configureStore } from "@reduxjs/toolkit";
import defaultslice from "./slices/defaultslice";
import questionControl from "./slices/QuestionControl";
import Settings from "./slices/Settings";
import userData from "./slices/userData";
import viewControl from "./slices/viewControl";

const store = configureStore({
    reducer: {
        defaultslice,
        viewControl,
        questionControl,
        userData,
        Settings
    }
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store