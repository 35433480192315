import { createSlice } from "@reduxjs/toolkit";

interface Setting{
    id:String,
    state: number,
    text?: string
}
const SettingsSlice = createSlice({
    name:"Settings",
    initialState:new Array<Setting>(),
    reducers:{
        setSetting:(state, payload: {payload: Setting})=>{
            
                state = [...state.filter(({id})=>id!=payload.payload.id), payload.payload]
            
        },
        initSettings:(state, payload: {payload: Array<Setting>})=>{
            state = [...state.filter(({id})=>!payload.payload.find((item)=>item.id==id)), ...payload.payload]
        }
    }
})

export const { setSetting, initSettings  } = SettingsSlice.actions

export default SettingsSlice.reducer

export type {Setting};