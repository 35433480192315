import { createSlice } from "@reduxjs/toolkit";
import { Answer, Question } from "../../interfaces";


export const counterSlice = createSlice({
    name: 'QuestionControl',
    initialState: {
        Widgets:new Array<{WidgetID: string, Questions: Array<{ group:string, Questions:Array<Question>}>}>()||[],
        selectedWidget:0,
        selectedQuestion:0,
        Answers:new Array<Answer>(),
        Rankings: new Array<{Widget: string, Rankings: Array<{Name: string, Match: number, climate_match: number; elevation_match: number; population_match: number, climate: number}>}>(),
        secondContact: true
    },
    reducers: {
        incrementQuestion:(state)=>{
            if(state.Widgets?.length){
                //console.log(state.selectedQuestion==state.Widgets[state.selectedWidget].Questions.length-1)
                if(state.selectedQuestion==state.Widgets[state.selectedWidget].Questions.length){
                    state.selectedQuestion = 0;
                    state.selectedWidget = state.selectedWidget+1
                }else{
                    state.selectedQuestion++
                }
            }
        },
        decrementQuestion:(state)=>{
            if(state.Widgets?.length){
                if(state.selectedQuestion==0){
                    if(state.selectedWidget>0){
                        state.selectedQuestion = state.Widgets[state.selectedWidget-1].Questions.length
                        state.selectedWidget =  state.selectedWidget-1
                    }
                }else{
                    state.selectedQuestion--;
                }
            }
        },
        setWidgets:(state, payload)=>{
            state.Widgets=payload.payload
        },
        setAnswers:(state, payload)=>{
            state.Answers = [...state.Answers.filter(({_to})=>!(payload.payload as Array<Answer>).find((A)=>A._to==_to)), ...(payload.payload as Array<Answer>)]
        },
        setRanking:(state, payload:{type: string, payload:{Widget: string, Rankings:Array<{Name: string, Match: number, climate_match: number; elevation_match: number; population_match: number, climate: number}>}})=>{
            state.Rankings = [...state.Rankings.filter(({Widget})=>Widget!=payload.payload.Widget), {...payload.payload}]
        },
        secondContactHappened:(state, payload)=>{
            state.secondContact = false
            state.selectedWidget = payload.payload.selectedWidget
            state.selectedQuestion = payload.payload.selectedQuestion
        },
        setSelectedWidget:(state, payload)=>{
            state.selectedWidget = payload.payload;
            state.selectedQuestion = 0;
        }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { incrementQuestion, decrementQuestion, setWidgets, setAnswers, setRanking, secondContactHappened, setSelectedWidget } = counterSlice.actions
  
  export default counterSlice.reducer